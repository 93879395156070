import { loadLanguageAsync } from "@/i18n/config";
import { RouteConfig } from "vue-router";
import LanguageModule from "@/store/modules/language.module";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { authenticate } from "@/services/api/authenticate.service";

const Layout = () =>
  import(/* webpackChunkName: "layout" */ "../../views/Layout/Layout.vue");
const Home = () =>
  import(/* webpackChunkName: "home" */ "../../views/Home/Home.vue");
const Account = () =>
  import(/* webpackChunkName: "account" */ "../../views/Account/Account.vue");
const ConfirmationPage = () =>
  import(
    /* webpackChunkName: "NewOrderingSubject" */ "../../views/Account/Components/PaymentsComponents/ConfirmationPage.vue"
  );

const authModule = getModule(AuthModule, store);
const languageModule = getModule(LanguageModule, store);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        beforeEnter: async function (to, from, next) {
          const result = await checkAuthorizations();
          result ? next() : next({ path: "/logout" });
        },
      },
      {
        path: "/account/:context?/:payments?",
        name: "account",
        component: Account,
        props: true,
        beforeEnter: async function (to, from, next) {
          const result = await checkAuthorizations();
          result ? next() : next({ path: "/logout" });
        },
      },
      // {
      //   path: "/payments",
      //   name: "payments",
      //   component: Payments,
      // },
      {
        path: "/confirmationpage",
        name: "confirmationpage",
        props: true,
        component: ConfirmationPage,
        beforeEnter: async function (to, from, next) {
          const result = await checkAuthorizations();
          result ? next() : next({ path: "/logout" });
        },
      },
      // {
      //   path: "/account/modifypassword",
      //   name: "modifypassword",
      //   component: ModifyPassword,
      // },
    ],
  },
];

async function checkAuthorizations(): Promise<boolean> {
  //   await loadLanguageAsync(languageModule.current);
  const res = (await authenticate.checkLogin()).data;
  return res;
}

const privateRoutes = routes.map((route) => route);
export default privateRoutes;
