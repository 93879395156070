import { i18n } from "vuelando";

function setI18nLanguage(lang: string): string {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang: string): Promise<string> {
  const msgs = await import(
    /* webpackChunkName: "lang-[request]" */ `./lang/${lang}-${lang.toUpperCase()}.json`
  );
  i18n.setLocaleMessage(lang, msgs.default);
  return setI18nLanguage(lang);
}

export { i18n };
