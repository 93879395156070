import { AuthState } from "@/models/utils/auth-state.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "auth" })
class AuthModule extends VuexModule {
  private authState: AuthState = null;

  get token(): boolean {
    return this.authState ? this.authState.token : null;
  }
  get role(): number[] {
    return this.authState != null ? this.authState.appRole : null;
  }
  get userName(): string {
    return this.authState
      ? this.authState.username
        ? this.authState.username
        : ""
      : "";
  }
  get userEmail(): string {
    return this.authState != null ? this.authState.email : "";
  }
  get userID(): number {
    return this.authState != null ? this.authState.userID : -1;
  }
  get profileRoleID(): number {
    return this.authState != null ? this.authState.profileRoleID : -1;
  }

  @Mutation
  public setLogin(state: AuthState): void {
    this.authState = state;
  }
  @Action
  public login(state: AuthState): void {
    this.context.commit("setLogin", state);
  }

  @Mutation
  public setLogout(): void {
    this.authState = null;
  }
  @Action
  public logout(): void {
    this.context.commit("setLogout");
  }

  @Action
  public setUsername(usesrname: string): void {
    this.context.commit("username", usesrname);
  }
  @Mutation
  public username(username: string): void {
    this.authState.username = username;
  }

  @Action
  public setEmail(email: string): void {
    this.context.commit("email", email);
  }
  @Mutation
  public email(email: string): void {
    this.authState.email = email;
  }

  @Action
  public setProfileRoleID(roleID: number): void {
    this.context.commit("roleID", roleID);
  }
  @Mutation
  public roleID(roleID: number): void {
    this.authState.profileRoleID = roleID;
  }
}

export default AuthModule;
