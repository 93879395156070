import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import privateRoutes from "@/router/routes/private-routes";
import publicRoutes from "@/router/routes/public-routes";
import errorRoutes from "./routes/error-routes";
import PageTitleModule from "@/store/modules/pageTitle.module";
import { AbortRequestsModule } from "vuelando";
import { loadLanguageAsync } from "@/i18n/config";
import LanguageModule from "@/store/modules/language.module";

const abortRequestsModule = getModule(AbortRequestsModule, store);

const authModule = getModule(AuthModule, store);
const Login = () => import("../views/Login/Login.vue");
const LayoutNoToken = () =>
  import(/* webpackChunkName: "layout" */ "../views/Layout/LayoutNoToken.vue");
const pageTitleModule = getModule(PageTitleModule, store);
const languageModule = getModule(LanguageModule, store);

Vue.use(VueRouter);

const baseRoutes: Array<RouteConfig> = [
  {
    name: "Login",
    path: "/login",
    component: LayoutNoToken,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
    ],
  },
  {
    name: "Logout",
    path: "/logout",
    component: () => {
      authModule.logout();
      router.push({ name: "Login" });
    },
  },
];

const routes: Array<RouteConfig> = [
  ...baseRoutes,
  ...errorRoutes,
  ...publicRoutes,
  ...privateRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeNames =
  [...publicRoutes, ...privateRoutes].flatMap((route) => {
    if (!route.children) return route.name;
    return route.children.map((c) => c.name);
  }) || [];

const errorNames =
  errorRoutes.flatMap((route) => {
    if (!route.meta || !route.meta.public) return [];
    if (!route.children) return route.name;
    return route.children.map((c) => c.name);
  }) || [];

const names = new Set(["Login", "Logout", ...routeNames, ...errorNames]);

router.beforeEach(function (to, from, next) {
  loadLanguageAsync(languageModule.current);
  abortRequestsModule.abort();
  pageTitleModule.resetBreadCrumb();

  if (authModule.token) {
    to.path === "/login" ? next({ path: "/" }) : next();
  } else {
    names.has(to.name) ? next() : next({ path: "/404" });
  }
});

export default router;
