import { LoginRequest } from "@/models/entities/login-request.interface";
import { AuthState } from "@/models/utils/auth-state.interface";
import { ApiService } from "vuelando";

const controller = "authenticate";

class AuthenticateService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  login(credentials: LoginRequest) {
    return this.post<LoginRequest, AuthState>(
      `${controller}/login`,
      credentials
    );
  }

  checkLogin() {
    return this.getAll<boolean>(`${controller}/status`);
  }

  logout() {
    return this.getAll<void>(`${controller}/logout`);
  }
}

export const authenticate = new AuthenticateService();
