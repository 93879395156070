import Vue from "vue";
import { AbortRequestsModule, SnackbarModule } from "vuelando";
import Vuex from "vuex";
import OverlayModule from "./modules/overlay.module";
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
import AuthModule from "@/store/modules/auth.module";
import PageTitleModule from "./modules/pageTitle.module";
import LanguageModule from "./modules/language.module";
import SearchBarModule from "./modules/searchBar.module";
import ThemeModule from "./modules/theme.module";
Vue.use(Vuex);

const vuexAuth = new VuexPersistence({
  key: "vuex-auth",
  restoreState: (key) => {
    return JSON.parse(Cookies.get(key) ? Cookies.get(key) : null);
  },
  saveState: (key, state) => {
    Cookies.set(key, JSON.stringify(state), {
      expires: 3,
    } as Cookies.CookieAttributes);
  },
  modules: ["auth"],
});

const vuexSettings = new VuexPersistence({
  key: "vuex-settings",
  restoreState: (key) => {
    return JSON.parse(Cookies.get(key) ? Cookies.get(key) : null);
  },
  saveState: (key, state) => {
    Cookies.set(key, JSON.stringify(state), {
      expires: 30,
    } as Cookies.CookieAttributes);
  },
  modules: ["settings"],
});

const vuexLanguage = new VuexPersistence({
  key: "cbi-language",
  restoreState: (key) => {
    return JSON.parse(Cookies.get(key) ? Cookies.get(key) : null);
  },
  saveState: (key, state) => {
    Cookies.set(key, JSON.stringify(state), {
      expires: new Date().addYears(9999),
    } as Cookies.CookieAttributes);
  },
  modules: ["language"],
});

const vuexTheme = new VuexPersistence({
  key: "cbi-theme",
  restoreState: (key) => {
    return JSON.parse(Cookies.get(key) ? Cookies.get(key) : null);
  },
  saveState: (key, state) => {
    Cookies.set(key, JSON.stringify(state), {
      expires: new Date().addYears(9999),
    } as Cookies.CookieAttributes);
  },
  modules: ["theme"],
});

const store = new Vuex.Store({
  modules: {
    overlay: OverlayModule,
    snackbar: SnackbarModule,
    abortRequests: AbortRequestsModule,
    auth: AuthModule,
    pagetitle: PageTitleModule,
    language: LanguageModule,
    theme: ThemeModule,
    searchbar: SearchBarModule,
  },
  plugins: [
    vuexAuth.plugin,
    vuexSettings.plugin,
    vuexLanguage.plugin,
    vuexTheme.plugin,
  ],
});

export default store;
