import { RouteConfig } from "vue-router";

const LayoutNoToken = () =>
  import(
    /* webpackChunkName: "layout" */ "../../views/Layout/LayoutNoToken.vue"
  );
const ResetPasswordByLink = () =>
  import(/* webpackChunkName: "account" */ "../../views/Login/Login.vue");

const VerifyEmailByLink = () =>
  import(
    /* webpackChunkName: "ErrorPage" */ "../../views/SignUp/VerifyEmailByLink.vue"
  );

const routes: Array<RouteConfig> = [
  {
    path: "/public",
    redirect: "public/404",
    component: LayoutNoToken,
    children: [
      {
        path: "/public/resetpasswordbylink",
        name: "resetPasswordByLink",
        component: ResetPasswordByLink,
      },
      // {
      //   path: "/public/signup",
      //   name: "signUp",
      //   component: SignUp,
      //   props: true,
      // },
      // {
      //   path: "/public/signUpCompleted",
      //   name: "signUpCompleted",
      //   component: SignUpCompleted,
      // },
      // {
      //   path: "/public/newOrderingSubject",
      //   name: "newOrderingSubject",
      //   component: NewOrderingSubject,
      // },
      {
        path: "/public/verifyEmailByLink",
        name: "verifyEmailByLink",
        component: VerifyEmailByLink,
      },
    ],
  },
];

const publicRoutes = routes.map((route) => route);
export default publicRoutes;
